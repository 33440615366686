@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  @font-face {
    font-family: 'WorkSans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../public/fonts/WorkSans-Regular.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Bitter';
    src: url(../public/fonts/Bitter.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Apercu';
    src: url(../public/fonts/apercu.woff2) format('woff2');
  }

  html {
    font-family: "Apercu", system-ui, sans-serif;
  }

  body {
    @apply text-white bg-slate-800 antialiased;
  }

  p {
    @apply mt-5 first:mt-0 leading-7;
  }

  ul {
    @apply list-disc list-outside ml-6;
  }

}